import React from 'react';
import { createIcon } from '../createIcon';
import { G, Path } from '../nbSvg';

export const InfoOutlineIcon = createIcon({
  viewBox: '0 0 20 20',
  path: (
    <G>
      <Path d="M9.94422 6.24C10.5628 6.24 11.0642 5.73856 11.0642 5.12C11.0642 4.50144 10.5628 4 9.94422 4C9.32566 4 8.82422 4.50144 8.82422 5.12C8.82422 5.73856 9.32566 6.24 9.94422 6.24Z" />
      <Path d="M12.4008 13.9996H10.8008V7.59961H8.40078C8.18861 7.59961 7.98513 7.68389 7.8351 7.83392C7.68507 7.98395 7.60078 8.18744 7.60078 8.39961C7.60078 8.61178 7.68507 8.81527 7.8351 8.9653C7.98513 9.11532 8.18861 9.19961 8.40078 9.19961H9.20078V13.9996H7.60078C7.38861 13.9996 7.18513 14.0839 7.0351 14.2339C6.88507 14.384 6.80078 14.5874 6.80078 14.7996C6.80078 15.0118 6.88507 15.2153 7.0351 15.3653C7.18513 15.5153 7.38861 15.5996 7.60078 15.5996H12.4008C12.613 15.5996 12.8164 15.5153 12.9665 15.3653C13.1165 15.2153 13.2008 15.0118 13.2008 14.7996C13.2008 14.5874 13.1165 14.384 12.9665 14.2339C12.8164 14.0839 12.613 13.9996 12.4008 13.9996Z" />
      <Path d="M10 0C8.02219 0 6.08879 0.58649 4.4443 1.6853C2.79981 2.78412 1.51809 4.3459 0.761209 6.17316C0.00433286 8.00042 -0.1937 10.0111 0.192152 11.9509C0.578004 13.8907 1.53041 15.6725 2.92894 17.0711C4.32746 18.4696 6.10929 19.422 8.0491 19.8078C9.98891 20.1937 11.9996 19.9957 13.8268 19.2388C15.6541 18.4819 17.2159 17.2002 18.3147 15.5557C19.4135 13.9112 20 11.9778 20 10C20 7.34783 18.9464 4.80429 17.0711 2.92893C15.1957 1.05357 12.6522 0 10 0V0ZM10 18.3333C8.35183 18.3333 6.74066 17.8446 5.37025 16.9289C3.99984 16.0132 2.93174 14.7117 2.30101 13.189C1.67028 11.6663 1.50525 9.99075 1.82679 8.37424C2.14834 6.75774 2.94201 5.27288 4.10745 4.10744C5.27288 2.942 6.75774 2.14833 8.37425 1.82679C9.99076 1.50525 11.6663 1.67027 13.189 2.301C14.7117 2.93173 16.0132 3.99984 16.9289 5.37025C17.8446 6.74066 18.3333 8.35182 18.3333 10C18.3333 11.0943 18.1178 12.178 17.699 13.189C17.2802 14.2001 16.6664 15.1187 15.8926 15.8926C15.1187 16.6664 14.2001 17.2802 13.189 17.699C12.178 18.1178 11.0944 18.3333 10 18.3333V18.3333Z" />
    </G>
  ),
});

export const InfoIcon = createIcon({
  viewBox: '0 0 24 24',
  d:
    'M12 0C9.62663 0 7.30655 0.703788 5.33316 2.02236C3.35977 3.34094 1.8217 5.21509 0.913451 7.4078C0.00519948 9.60051 -0.232441 12.0133 0.230582 14.3411C0.693605 16.6689 1.83649 18.807 3.51472 20.4853C5.19295 22.1635 7.33115 23.3064 9.65892 23.7694C11.9867 24.2324 14.3995 23.9948 16.5922 23.0865C18.7849 22.1783 20.6591 20.6402 21.9776 18.6668C23.2962 16.6935 24 14.3734 24 12C23.9966 8.81846 22.7312 5.76821 20.4815 3.51852C18.2318 1.26883 15.1815 0.00344108 12 0ZM12.25 5C12.5467 5 12.8367 5.08797 13.0834 5.25279C13.33 5.41762 13.5223 5.65189 13.6358 5.92597C13.7494 6.20006 13.7791 6.50166 13.7212 6.79263C13.6633 7.08361 13.5204 7.35088 13.3107 7.56066C13.1009 7.77044 12.8336 7.9133 12.5426 7.97118C12.2517 8.02905 11.9501 7.99935 11.676 7.88582C11.4019 7.77229 11.1676 7.58003 11.0028 7.33335C10.838 7.08668 10.75 6.79667 10.75 6.5C10.75 6.10217 10.908 5.72064 11.1893 5.43934C11.4706 5.15803 11.8522 5 12.25 5ZM14.5 18.5H10.5C10.2348 18.5 9.98043 18.3946 9.7929 18.2071C9.60536 18.0196 9.5 17.7652 9.5 17.5C9.5 17.2348 9.60536 16.9804 9.7929 16.7929C9.98043 16.6054 10.2348 16.5 10.5 16.5H11.25C11.3163 16.5 11.3799 16.4737 11.4268 16.4268C11.4737 16.3799 11.5 16.3163 11.5 16.25V11.75C11.5 11.6837 11.4737 11.6201 11.4268 11.5732C11.3799 11.5263 11.3163 11.5 11.25 11.5H10.5C10.2348 11.5 9.98043 11.3946 9.7929 11.2071C9.60536 11.0196 9.5 10.7652 9.5 10.5C9.5 10.2348 9.60536 9.98043 9.7929 9.79289C9.98043 9.60536 10.2348 9.5 10.5 9.5H11.5C12.0304 9.5 12.5391 9.71071 12.9142 10.0858C13.2893 10.4609 13.5 10.9696 13.5 11.5V16.25C13.5 16.3163 13.5263 16.3799 13.5732 16.4268C13.6201 16.4737 13.6837 16.5 13.75 16.5H14.5C14.7652 16.5 15.0196 16.6054 15.2071 16.7929C15.3946 16.9804 15.5 17.2348 15.5 17.5C15.5 17.7652 15.3946 18.0196 15.2071 18.2071C15.0196 18.3946 14.7652 18.5 14.5 18.5Z',
});
